import React, { Fragment } from 'react';
import styled from 'styled-components';

import { InputSquare } from '../../styled';
import Checkbox from '../Checkbox/';

import { bowlOptions } from '../../../util/wrappings';
import check from '../Ingredient/check.png';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0.95rem;

  > img {
    margin-left: 1rem;
  }

  input {
    display: none;
  }
`;

const Bowl = props => {
  const { data, image, id, selectedData, setWrapping, addIngredient } = props;
  const imageUrl = image && `${process.env.PUBLIC_URL}/img/${image}.png`;
  const isSelected = selectedData.wrapping.key && selectedData.wrapping.key === id;
  const handleChange = async (category, item) => {
    await addIngredient(category, item)
    await setWrapping(data)
  }
  return (
    <Fragment>
      <Wrapper>
        <label htmlFor={id}>
          <InputSquare>{isSelected && <img src={check} height="20" alt="check" />}</InputSquare>
          <input id={id} name="wrapping" type="radio" value="bowl" onChange={() => setWrapping(data)} />
        </label>
        {image && <img src={imageUrl} width="auto" height="90" alt={data.title} />}
      </Wrapper>
      <div>
        {bowlOptions.map(item => (
          <Checkbox
            key={item.key}
            id={item.key}
            type="checkbox"
            checked={selectedData[item.category].map(value => value.key).includes(item.key)}
            handleChange={() => {handleChange(item.category, item)}}
          >
            {item.display}
          </Checkbox>
        ))}
      </div>
    </Fragment>
  );
};

export default Bowl;
