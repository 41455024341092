import React from 'react';
import styled, { css } from 'styled-components';

import logo from './logo.svg';

const StyledLogo = styled.img`
  position: absolute;
  top: 0;
  left: ${props => (props.align === 'center' ? '50%' : '-20px')};
  max-width: ${props => (props.size ? props.size : '600px')}
  z-index: 99;

  ${props =>
    props.align === 'center' &&
    css`
      transform: translateX(-50%);
    `}
`;

const Logo = props => <StyledLogo src={logo} alt="Bamban Sushi" size={props.size} align={props.align} />;

export default Logo;
