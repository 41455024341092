import React, { Component } from "react";
import axios from "axios";
import validator from "validator";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import { Row, Column } from "styled-bootstrap-components";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import MediaQuery from 'react-responsive';

import SubmitButton from "./SubmitButton";
import theme from "../styled/theme";
import { HelpText } from "../styled";

import { drinks, desserts } from "../../../src/util/extras";
import Extra from "../ItemForm/Ingredient/Extra";
import { AppContext } from "../AppProvider";
import Errors from './Errors';

const renderExtraList = (props) => (
	<Extra
		key={props.data.key}
		id={props.data.key}
		data={props.data}
		image={props.data.key}
		sodas={props.sodas}
		juices={props.juices}
		setExtra={props.setExtra}
		showPrice={props.showPrice}
	/>
);

const ErrorMessage = styled.div`
	color: ${theme.colors.primary};
`;

const required = (value) => {
	if (!value.toString().trim().length) {
		// We can return string or jsx as the 'error' prop for the validated Component
		return <ErrorMessage>Requerido.</ErrorMessage>;
	}
};

const email = (value) => {
	if (!validator.isEmail(value)) {
		return <ErrorMessage>El correo {value} no es válido.</ErrorMessage>;
	}
};

const phone = (value) => {
	if (!validator.isMobilePhone(value, "en-US")) {
		return <ErrorMessage>El teléfono {value} no es válido.</ErrorMessage>;
	}
};

const Title = styled.h2`
	font-family: "Oneer";
	font-size: 2.25rem;
	line-height: 2.25rem;
	margin: 0;
	text-transform: uppercase;
	word-break: break-word;

	@media (max-width: 767px) {
		font-size: 2rem;
		line-height: 2rem;
	}
`;

const Label = styled.label`
	display: none;
`;

const StyledInput = styled(Input)`
	background: ${theme.colors.icecream};
	border: 1px solid ${theme.colors.whiter};
	border-radius: 8px;
	font-size: 1rem;
	padding: 1rem;
	width: 100%;
	box-sizing: border-box;
`;

const StyledCheckbox = styled('label')`
	padding: 1rem 16px;
    display: inline-block;
    cursor: pointer;
    border-radius: 8px;
    width: 100%;
    display: flex;
    justify-content: space-between;
	align-items: center;
    background: #faf6f2;
    border: 1px solid #373535;
`

const initialErrors = {
	quantity: 'Minimo de orden para delivery 375 pesos, completar la orden para continuar.'
};



class CheckoutForm extends Component {

	state = {
		firstName: this.props.context.user.firstName,
		lastName: this.props.context.user.lastName,
		email: this.props.context.user.email,
		phone: this.props.context.user.phone,
		addressOne: this.props.context.user.addressOne,
		addressTwo: this.props.context.user.addressTwo,
		city: "Santo Domingo",
		neighborhood: this.props.context.user.neighborhood,
		takeout: this.props.context.user.takeout,
		extras: [],
		sodas: [],
		juices: [],
		isErrorsModalOpen: false,
		formErrors: initialErrors,
		disabledSubmitButton: false
	};

	closeErrorsModal = () => {
		this.setState({ isErrorsModalOpen: false });
	};

	handleFormChange = () => {
		const user = {
			firstName: this.state.firstName,
			lastName: this.state.lastName,
			email: this.state.email,
			phone: this.state.phone,
			addressOne: this.state.addressOne,
			addressTwo: this.state.addressTwo,
			city: "Santo Domingo",
			neighborhood: this.state.neighborhood,
			takeout: this.state.takeout
		};

		this.props.addUserData(user);
	};

	setExtra = (data, indexSoda, val = null) => {
		let extras = JSON.parse(JSON.stringify(this.props.context.orderExtra));
		let index = extras.findIndex((o) => o.id === data.id);

		if (data.key === "refresco") {
			if (val) {
				let count = 0;
				let i = extras.findIndex((oe) => {
					if (oe.key === "refresco") {
						if (indexSoda === count) {
							return true;
						}
						count++;
					}
					return false
				});

				extras[i].title = val;
				extras[i].id = val;
			} else {
				if (!data.quantity) {
          let i;
          extras.findIndex((oe, index) => {
            if (oe.key === "refresco") {
              i = index
			}
			return false
          });
					extras.splice(i, 1);
				} else {
					extras = [...extras, data];
				}
			}
		} else if (data.key === "jugo") {
      if (val) {
        let count = 0;
        let i = extras.findIndex((oe) => {
          if (oe.key === "jugo") {
            if (indexSoda === count) {
              return true;
            }
            count++;
          }
          return false;
        });

        extras[i].title = val;
        extras[i].id = val;
      } else {
        if (!data.quantity) {
          let i;
          extras.findIndex((oe, index) => {
            if (oe.key === "jugo") {
              i = index;
            }
            return false;
          });
          extras.splice(i, 1);
        } else {
          extras = [...extras, data];
        }
      } 
		} else {
			if (index !== -1) {
				if (data.quantity === 0) {
					extras.splice(index, 1);
				} else {
					extras[index].quantity = data.quantity;
				}
			} else {
				extras = [...extras, data];
			}
		}

		this.props.context.setExtraItem(extras);
	};

	handleInputChange = ({ target }) => {
		const { name, value } = target;
		this.setState(
			{
				[name]: value,
			},
			this.handleFormChange
		);
	};

	handleCheckboxChange = async ({target}) => {
		const { name, checked } = target;
		this.setState(
			{
				addressOne: checked ? 'Take Out.' : '',
				addressTwo: checked ? 'Voy pa’ allá.' : '',
				neighborhood: checked ? 'Lo pasaré a buscar.' : '',
				[name]: checked,
			},
			this.handleFormChange
		);
	}

	handleOrderSubmit = (context) => {
		if(!this.state.takeout && context.total < 375) {
			this.setState({
				isErrorsModalOpen: true
			})
			return;
		}	
		
		this.setState({
			disabledSubmitButton: true
		})

		context.setLoadingTopBar(true)
		let data = {
			order: context.order,
			orderExtra: context.orderExtra,
			user: context.user,
			orderNumber: context.orderNumber,
			total: context.total,
			deliveryAmount: context.deliveryAmount,
			tax: context.tax,
			serviceFee: context.serviceFee
		}
		
		axios
			.post("https://bambansushi.com/api/orden", data)
			.catch((error) => {
				console.log(error)
				context.setLoadingTopBar(false)
				this.setState({
					disabledSubmitButton: false
				})
				// alert('error al procesar la orden')
			})
			.then(() => {
				context.setLoadingTopBar(false)
				localStorage.removeItem("orderBambanSushi")
				localStorage.removeItem("orderExtraBambanSushi")
				this.setState({
					disabledSubmitButton: false
				})
				context.insertContact(data)
				this.props.history.push("/ordenar/confirmacion");
			});

	};

	render() {
		return (
			<Form>
				{!this.state.formValid && (
					<Errors
					  formErrors={this.state.formErrors}
					  open={this.state.isErrorsModalOpen}
					  onClose={this.closeErrorsModal}
					  close={this.closeErrorsModal}
					  closeOnDocumentClick
					/>
				  )}
				<Row>
					<Column sm={12} mb="1rem">
						<Title>Informacion de Contacto</Title>
					</Column>
					<Column sm={6} mb="1rem">
						<div>
							<Label htmlFor="firstName">Nombre:</Label>
							<StyledInput
								name="firstName"
								id="firstName"
								type="text"
								placeholder="Nombre"
								value={this.state.firstName}
								onChange={this.handleInputChange}
								validations={[required]}
								required
							/>
						</div>
					</Column>
					<Column sm={6} mb="1rem">
						<div>
							<Label htmlFor="lastName">Apellido:</Label>
							<StyledInput
								name="lastName"
								id="lastName"
								type="text"
								placeholder="Apellido"
								value={this.state.lastName}
								onChange={this.handleInputChange}
								validations={[required]}
								required
							/>
						</div>
					</Column>
					<Column sm={6} mb="1rem">
						<div>
							<Label htmlFor="email">Correo electrónico:</Label>
							<StyledInput
								name="email"
								id="email"
								type="email"
								placeholder="Correo electrónico"
								value={this.state.email}
								onChange={this.handleInputChange}
								validations={[required, email]}
								required
							/>
						</div>
					</Column>
					<Column sm={6} mb="1rem">
						<div>
							<Label htmlFor="phone">#WhatsApp:</Label>
							<StyledInput
								name="phone"
								id="phone"
								type="phone"
								placeholder="#WhatsApp"
								value={this.state.phone}
								onChange={this.handleInputChange}
								validations={[required, phone]}
								required
							/>
						</div>
					</Column>
				</Row>

				<Row>
					<Column sm={12} mb="1rem">
						<Title>Direccion de Entrega</Title>
					</Column>
					<Column sm={12} mb="1rem">
						<div>
							<Label htmlFor="addressOne">Calle y número:</Label>
							<StyledInput
								name="addressOne"
								id="addressOne"
								type="text"
								placeholder="Calle y número:"
								value={this.state.addressOne}
								onChange={this.handleInputChange}
								validations={[required]}
								required
							/>
						</div>
					</Column>
					<Column sm={12} mb="1rem">
						<div>
							<Label htmlFor="addressTwo">
								Edificio / Apartamento / Residencial:
							</Label>
							<StyledInput
								name="addressTwo"
								id="addressTwo"
								type="text"
								placeholder="Edificio / Apartamento / Residencial"
								value={this.state.addressTwo}
								onChange={this.handleInputChange}
								validations={[required]}
								required
							/>
						</div>
					</Column>
					<Column sm={6} mb="1rem">
						<div>
							<Label htmlFor="neighborhood">Sector:</Label>
							<StyledInput
								name="neighborhood"
								id="neighborhood"
								type="text"
								placeholder="Sector"
								value={this.state.neighborhood}
								onChange={this.handleInputChange}
								validations={[required]}
								required
							/>
						</div>
					</Column>
					<Column sm={6} mb="1rem">
						<div>
							<Label htmlFor="city">Ciudad:</Label>
							<StyledInput
								name="city"
								id="city"
								type="text"
								placeholder="Ciudad"
								value={this.state.city}
								onChange={this.handleInputChange}
								required
							/>
						</div>
					</Column>
					<Column sm={6} mb="1rem">
						<div>
							<StyledCheckbox htmlFor="takeout"> Take Out (Pasar a buscar)
							<input
								name="takeout"
								id="takeout"
								type="checkbox"
								defaultChecked={this.state.takeout}
								value={this.state.takeout}
								onChange={this.handleCheckboxChange}
								required
							/>
							</StyledCheckbox>
							
						</div>
					</Column>
				</Row>
				<AppContext.Consumer>
					{(context) => (
						<Row>
							<Column sm={6} mb="1rem">
								<Title>ELEGIR BEBIDA</Title>
								{JSON.parse(JSON.stringify(drinks)).map((data) => {
									let index;
									let sodas = [];
									let juices = [];
									if (data.key === "refresco") {
										context.orderExtra.forEach((oe) => {
											if (oe.key === "refresco") {
												sodas.push(oe);
											}
										});
										data.quantity = sodas.length;
									} else if (data.key === "jugo") {
                    context.orderExtra.forEach((oe) => {
                      if (oe.key === "jugo") {
                        juices.push(oe);
                      }
                    });
                    data.quantity = juices.length;
                  } else {
										if (context.orderExtra) {
											index = context.orderExtra.findIndex(
												(oe) => {
													return oe.id === data.id;
												}
											);
											if (index !== -1) {
												data.quantity =
													context.orderExtra[
														index
													].quantity;
                      }
										}
                  }
									return renderExtraList({
										data,
										...this.props,
										showPrice: true,
										sodas: sodas,
										juices: juices,
										setExtra: this.setExtra,
									});
								})}
							</Column>
							<Column sm={6}>
								<Title>ELEGIR POSTRE</Title>
								{JSON.parse(JSON.stringify(desserts)).map((data) => {
									if (context.orderExtra) {
										let index = context.orderExtra.findIndex(
											(oe) => {
												return oe.id === data.id;
											}
										);
										if (index !== -1) {
											data.quantity =
												context.orderExtra[
													index
												].quantity;
										}
									}
									return renderExtraList({
										data,
										...this.props,
										showPrice: true,
										setExtra: this.setExtra,
									});
								})}
							</Column>
						</Row>
					)}
				</AppContext.Consumer>
				<Row>
					<Column sm={12}>
						<MediaQuery maxDeviceWidth={767}>
							<br></br>
						</MediaQuery>
						<SubmitButton
							lg
							disabled={this.state.disabledSubmitButton}
							id="checkout-button"
							type="button"
							bg="primary"
							color="white"
							width="100%"
							onClick={() =>
								this.handleOrderSubmit(this.props.context)
							}
							formValid={this.props.context.order.length >= 1}
						>
							Checkout
						</SubmitButton>
						<HelpText>
							<FontAwesomeIcon icon={faInfoCircle} size="lg" />
							Al presionar el botón de checkout tu orden será colocada.
							Puedes decidir cómo pagar al momento que tu pedido
							llegue a la dirección indicada. Se tendrá a tu
							disposición un veriphone y/o los recursos necesarios
							para que puedas realizar tu pago en efectivo.
						</HelpText>
					</Column>
				</Row>
			</Form>
		);
	}
}

export default CheckoutForm;
