import React from 'react';
import styled from 'styled-components';
import MediaQuery from 'react-responsive';
import { Container, Row, Column } from 'styled-bootstrap-components';

import { AppContext } from '../AppProvider';
import Header from '../Header';
import OrderSummary from '../OrderSummary';
import { Button, Layout } from '../styled';

const Title = styled.h2`
  font-family: 'Oneer';
  font-size: 2.25rem;
  margin: 0;
  text-transform: uppercase;
`;

const Wrapper = styled.section`
  background: white;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 2rem;
  word-break: break-word;
`;

const handleSubmit = (props, context) => {
  context.resetState();
  props.history.push('/');
};

const Confirmation = props => (
  <div>
    <MediaQuery maxDeviceWidth={767}>
      <Header hideCart />
    </MediaQuery>
    <Layout>
      <AppContext.Consumer>
        {context => (
          <Container>
            <Row>
              <Column sm={8}>
                <Wrapper>
                  <Title>Tu orden ha sido recibida!</Title>
                  <p>Ya procedimos a elaborarla.</p>
                  <p>
                    Para que puedas revisar tu orden, te fue enviado un resumen al correo electrónico provisto. ({
                      context.user.email
                    })
                  </p>
                  <p>Te estaremos contactando vía Whatsapp al {context.user.phone} para solicitarte el location del lugar de entrega para hacer mas fácil la llegada y para darte a conocer las opciones de forma de pago y que elijas. </p>
                  <p>Tu orden estará llegando en los próximos 45 a 90 minutos, según como esté nuestra demanda, a la siguiente dirección:</p>
                  <div>{context.user.addressOne}</div>
                  <div>{context.user.addressTwo}</div>
                  <div>{context.user.neighborhood}</div>
                  <div>{context.user.city}</div>
                  <p>
                  Para cualquier interrogante, imprevisto o eventualidad puede contactarnos vía Whatsapp al <a href="https://wa.me/18098797427">809-879-7427</a>
                  </p>
                  <br />
                  <Button bg="primary" mt="1rem" width="100%" onClick={() => handleSubmit(props, context)}>
                    Volver al inicio &raquo;
                  </Button>
                </Wrapper>
              </Column>
              <Column sm={4}>
                <OrderSummary
                  history={props.history}
                  order={context.order}
                  orderExtra={context.orderExtra}
                  total={context.total}
                  tax={context.tax}
                  deliveryAmount={context.deliveryAmount}
                  serviceFee={context.serviceFee}
                  removeItem={context.removeItem}
                  removeItemExtra={context.removeItemExtra}
                />
              </Column>
            </Row>
          </Container>
        )}
      </AppContext.Consumer>
    </Layout>
  </div>
);

export default Confirmation;
