import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { color, fontSize, width, space, top, left, right, bottom } from 'styled-system';

import backgroundImage from './bg.jpg';
import theme from './theme';

export const Layout = styled.section`
  background: url(${backgroundImage});
  background-size: cover;
  border: 4px solid ${theme.colors.whiter};
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
  margin: 0 auto;
  max-width: 1140px;
  min-height: calc(100vh - 7rem - 8px);
  padding: 2rem 1rem 5rem;
  
  position: relative;
  
  @media (min-width: 768px) {
    overflow: hidden;
  }
`;

export const PrintContainer = styled.div`
  @media screen {
    display: none;
  }
`;

export const Title = styled.h3`
  color: ${theme.colors.primary};
  font-family: 'Chunq';
  font-size: 40px;
  letter-spacing: 1px;
  margin: 0.25rem 0;
  text-align: center;
`;

export const FlexRow = styled.div`
  display: flex;
  align-items: ${({ align }) => (align && align) || 'flex-start'};
  flex-direction: ${({ direction }) => (direction && direction) || 'row'};
  justify-content: ${({ justify }) => (justify && justify) || 'flex-start'};

  ${({ direction }) =>
    direction === 'column' &&
    css`
      > div:not(:last-child) {
        margin-bottom: 1rem;
      }
    `};
`;

export const InputSquare = styled.div`
  background: white;
  border: 2px solid #272525;
  border-radius: ${props => (props.circle ? '50%' : '0')};
  cursor: pointer;
  display: flex;
  justify-content: center;
  height: 20px;
  width: 20px;
`;

export const DollarSign = styled.span`
  font-family: 'Myriad Pro';
  font-size: 0.75em;
`;

export const ButtonLink = styled(Link)`
  color: ${props => (props.color && props.color) || 'white'};
  cursor: pointer;
  border: 0;
  border-bottom: ${({ border }) => (border ? '4px solid green' : 'none')};
  border-radius: ${({ circle }) => (circle ? '50%' : '5px')};
  display: inline-block;
  font-family: ${({ groovy }) => (groovy ? 'inherit' : 'Arial, Helvetica, sans-serif')};
  font-size: ${({ groovy }) => (groovy ? '36px' : '18px')};
  padding: ${props => (props.padding ? props.padding : '10px 15px')};
  text-align: center;
  text-transform: ${({ groovy }) => (groovy ? 'uppercase' : 'none')};
  text-decoration: none;
  min-width: ${props => (props.width ? props.width : 'auto')};
  height: ${props => (props.height ? props.height : 'auto')};
  ${color};
  ${fontSize};
  ${space};
  ${width};

  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }

  ${({ circle }) =>
    circle &&
    css`
      display: flex;
      align-items: center;
      justify-content: center;
    `};
`;

export const Button = ButtonLink.withComponent('button');

export const SectionLabel = styled.div`
  font-family: 'Oneer';
  font-size: 40px;
  background: ${props => (props.background ? props.background : '#a9c675')};
  color: ${props => (props.color ? props.color : theme.colors.grayDark)};
  width: 150px;
  padding: 0.75rem 1rem;
  position: absolute;
  top: 40%;
  line-height: 0.75;
`;

export const Disabled = styled.div`
  color: ${theme.colors.primary};
  font-family: 'Chunq';
  font-size: 50px;
  letter-spacing: 1px;
  margin: 0.25rem 0;
  text-align: center;
  position: absolute;
  transform: translateX(-50%) rotate(-20deg);
  ${top};
  ${left};
  ${right};
  ${bottom};
`;

export const Canceled = styled.div`
  color: ${theme.colors.primary};
  font-family: 'Chunq';
  font-size: 40px;
  letter-spacing: 1px;
  margin: 0.25rem 0;
  text-align: center;
  position: absolute;
  transform: translateX(-50%) rotate(-20deg);
  background: rgba(255,255,255,0.8);
  ${top};
  ${left};
  ${right};
  ${bottom};
`;

export const HelpText = styled.div`
  color: ${theme.colors.gray};
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 10px;
  font-size: 0.825rem;
  margin: 1rem 0;
  word-break: normal;
`;
