import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import MediaQuery from 'react-responsive';

import CustomerInfo from './CustomerInfo';
import IngredientList from './IngredientList';
import Errors from './Errors';
import { Button, ButtonLink } from '../styled';

import { bowlOptions } from '../../util/wrappings';
import { isMobile } from '../../helpers';

const ButtonRow = styled.div`
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  padding: 1rem 0;
  text-align: center;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
`;

const initialErrors = {
  quantity: 'Por favor elige la cantidad que deseas ordenar',
  wrapping: 'Por favor elige el tipo de plato que quieres ordenar',
  ingredients: 'Por favor elige al menos una proteina o un complemento',
};

const initialState = {
  name: '',
  quantity: 0,
  wrapping: {},
  proteins: [],
  sauces: [],
  complements: [],
  toppings: [],
  bowlOptions: [],
  isErrorsModalOpen: false,
  formErrors: initialErrors,
  formValid: false,
  ingredientLimit: ``,
  modal: false
};

class OrderForm extends Component {
  state = initialState;

  componentDidUpdate = (prevProps, prevState) => {
    // TODO fix this
    // When the user changes the Wrapping type, we also need to re-validate the quantity
    // in case they select a wrapping that does not support the selected quantity.
    if (this.state.wrapping !== prevState.wrapping) {
      this.validateField('quantity', this.state.quantity);
    }
  };

  setQuantity = q => {
    this.setState(
      {
        quantity: q,
      },
      () => {
        this.validateField('quantity', q);
      }
    );
  };

  setWrapping = wrapping => {
    this.setState(
      {
        wrapping,
      },
      async () => {
        // Validate Wrapping
        await this.validateField('wrapping', wrapping);
        if (wrapping.key === 'bowl') {
          if(!this.state.bowlOptions.length) {
            this.addIngredient('bowlOptions', bowlOptions[1]);
          }
        } else {
          this.clearIngredient('bowlOptions');
        }
      }
    );

  };

  
  validateField(fieldName, value) {
    const fieldValidationErrors = Object.assign({}, this.state.formErrors);
    let { quantityValid, wrappingValid, ingredientsValid } = this.state;
    const { proteins, complements } = this.state;
    let errorMessage = '';
    switch (fieldName) {
      case 'quantity':
        if (this.state.wrapping.category === 'cono' || this.state.wrapping.category === 'bowls') {
          quantityValid = value >= 0.5 && value <= 9.5 && value % 1 === 0;
          if (value % 1 !== 0) {
            errorMessage = `Solo puedes elegir conos o bowls en numeros enteros`;
          }
        } else {
          quantityValid = value >= 0.5 && value <= 9.5 && value % 0.5 === 0;
          if (value % 0.5 !== 0) {
            errorMessage = `Por favor elige una cantidad en incrementos de 0.5`;
          }
        }

        if (value < 0.5 || value > 9.5) {
          errorMessage = 'Por favor elige una cantidad entre 0.5 y 9.5';
        }

        fieldValidationErrors.quantity = quantityValid ? '' : errorMessage;
        break;
      case 'wrapping':
        if(this.state.quantity) {
          if(value.category === 'cono' || value.category === 'bowls') {
            if (this.state.quantity % 1 !== 0) {
              fieldValidationErrors.quantity = `Solo puedes elegir conos o bowls en numeros enteros`;
              quantityValid = false;
            }
          } else {
            quantityValid = this.state.quantity >= 0.5 && this.state.quantity <= 9.5 && this.state.quantity % 0.5 === 0;
            if (this.state.quantity % 0.5 !== 0) {
              errorMessage = `Por favor elige una cantidad en incrementos de 0.5`;
            }
            if (this.state.quantity < 0.5 || this.state.quantity > 9.5) {
              errorMessage = 'Por favor elige una cantidad entre 0.5 y 9.5';
            }
            fieldValidationErrors.quantity = quantityValid ? '' : errorMessage;
          }
        }
        wrappingValid = value !== undefined;
        fieldValidationErrors.wrapping = wrappingValid ? '' : 'Por favor elige el tipo de plato que quieres ordenar.';
        break;
      default:
        ingredientsValid = proteins.length > 0 || complements.length > 0;
        fieldValidationErrors.ingredients = ingredientsValid
          ? ''
          : 'Por favor elige al menos una proteina o un complemento';
        break;
    }
    this.setState(
      { formErrors: fieldValidationErrors, quantityValid, wrappingValid, ingredientsValid },
      this.validateForm
    );
  }

  validateForm() {
    this.setState({
      formValid: this.state.ingredientsValid && this.state.quantityValid && this.state.wrappingValid,
    });
  }

  handleInputChange = ({ target }) => {
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    this.setState(
      {
        [name]: value,
      },
      () => {
        this.validateField(name, value);
      }
    );
  };

  addIngredient = (category, ingredient) => {
    const ingredients = [...this.state[category]];
    const ingredientKeys = ingredients.map(item => item.key);
    

    // If ingredient already exists in the category array, remove it
    if (ingredientKeys.includes(ingredient.key)) {
      const itemToRemove = ingredients.find(item => item.key === ingredient.key);
      const index = ingredients.indexOf(itemToRemove);

      this.setState(
        {
          [category]: [...this.state[category].slice(0, index), ...this.state[category].slice(index + 1)],
        },
        // If ingredient is removed, we need to revalidate in case all the ingredients are empty
        this.validateField
      );
      // If it doesn't exist, add it to the category array
    } else {
      let ingredientLimit;
      if(ingredient.category === 'proteins') {
        if(this.state.proteins.length >= 3) {
          ingredientLimit = 'No puede elegir mas de 3 Proteínas'
        }
      }
      if(ingredient.category === 'complements') {
        if(this.state.complements.length >= 5) {
          ingredientLimit = 'No puede elegir mas de 5 Complementos'
        }
      }
      if(ingredient.category === 'sauces') {
        if(this.state.sauces.length >= 2) {
          ingredientLimit = 'No puede elegir mas de 2 Salsas'
        }
      }
      if(ingredient.category === 'toppings') {
        if(this.state.toppings.length >= 2) {
          ingredientLimit = 'No puede elegir mas de 2 Topping'
        }
      }
      if(ingredientLimit) {
        this.setState({
          ingredientLimit: ingredientLimit,
          isErrorsModalOpen: true
        }) 
      } else {
        this.setState({ [category]: [...this.state[category], ingredient] }, this.validateField);
      }
    }

    // If user selects any of the bowl options,
    // Wrapping type should be set to 'bowl'
    // if (category === 'bowlOptions') {
    //   this.setState({
    //     wrapping: bowls[0],
    //   });
    // }
  };

  clearIngredient = category => {
    this.setState({
      [category]: [],
    });
  };

  removeIngredient = (category, ingredient) => {
    const ingredients = { ...this.state[category] };
    ingredients[ingredient.key] = null;
    this.setState({ ingredients });
  };

  openErrorsModal = () => {
    this.setState({ isErrorsModalOpen: true });
  };

  closeErrorsModal = () => {
    this.setState({ isErrorsModalOpen: false, ingredientLimit: '' });
  };

  openModal = (e) => {
    e.preventDefault();
    this.setState({ modal: true });
  };

  closeModal = () => {
    this.setState({ modal: false });
  };


  handleFormSubmit = e => {
    e.preventDefault();

    const { history, context } = this.props;

    const item = {
      name: this.state.name,
      quantity: this.state.quantity,
      wrapping: this.state.wrapping,
      proteins: this.state.proteins,
      sauces: this.state.sauces,
      complements: this.state.complements,
      toppings: this.state.toppings,
      bowlOptions: this.state.bowlOptions,
      totalPrice: parseFloat(this.state.quantity) * parseFloat(this.state.wrapping.price),
    };

    if (this.state.formValid) {
      context.addItem(item);
      window.scrollTo(0, 0);

      if (isMobile) {
        history.push('/ordenar/checkout');
      } else {
        history.push('/ordenar/checkout');
      }
    } else {
      this.openErrorsModal();
    }
  };

  resetState = () => {
    this.setState(initialState);
  };

  render() {
    return (
      <Fragment>
        <form>
          <CustomerInfo
            {...this.props}
            name={this.state.name}
            quantity={parseFloat(this.state.quantity)}
            handleChange={this.handleInputChange}
            setQuantity={this.setQuantity}
          />
          <IngredientList addIngredient={this.addIngredient} setWrapping={this.setWrapping} selectedData={this.state} />
          <ButtonRow>
            <Button onClick={this.openModal} bg="primary" mr="1rem">
              Limpiar Campos {this.state.modal}
            </Button>
            <Button onClick={this.handleFormSubmit} type="button" bg="green">
              Agregar
            </Button>
            <MediaQuery minDeviceWidth={768}>
              {this.props.context.order.length > 0 && (
                <ButtonLink style={{ position: 'absolute', right: '0' }} to="/ordenar/checkout">
                  Volver al Checkout &rarr;
                </ButtonLink>
              )}
            </MediaQuery>
          </ButtonRow>
        </form>
        { this.state.ingredientLimit ? (
          <Errors
            formErrors={{}}
            subtitle={this.state.ingredientLimit}
            open={this.state.isErrorsModalOpen}
            onClose={this.closeErrorsModal}
            close={this.closeErrorsModal}
            closeOnDocumentClick
          />
        ) : !this.state.formValid ? (
          <Errors
            formErrors={this.state.formErrors}
            open={this.state.isErrorsModalOpen}
            onClose={this.closeErrorsModal}
            close={this.closeErrorsModal}
            subtitle={'Por favor corrige estos errores para continuar:'}
            closeOnDocumentClick
          />) : ''}
        {this.state.modal && <Errors
            formErrors={[]}
            open={this.state.modal}
            onClose={this.closeModal}
            close={this.closeModal}
            subtitle={'Deseas limpiar los campos seleccionados'}
            closeOnDocumentClick
            reset={this.resetState}
            btn={'Limpiar Campos'}
          />}
      
      </Fragment>
    );
  }
}

export default OrderForm;
