import React from 'react';
import styled from 'styled-components';

import QuantitySelector from '../QuantitySelector';
import theme from '../../styled/theme';

const Wrapper = styled.div`
  background: ${theme.colors.icecream};
  border-radius: 15px;
  box-shadow: 0 0 8px rgba(57, 56, 56, 0.7);
  padding: 1rem 0.5rem;
`;

const Input = styled.input`
  border: 0;
  background: none;
  border-bottom: 2px solid ${theme.colors.grayDark};
  color: ${theme.colors.primary};
  font-size: 20px;
  font-weight: bold;
  margin: 0 0.5rem;
  min-height: 2rem;
  width: ${props => (props.width ? props.width : 'auto')};

  @media (max-width: 767px) {
    font-size: 1rem;
    margin-bottom: 1.125rem;
    width: auto;

    &[name='name'] {
      min-width: 210px;
    }

    &[name='quantity'] {
      width: 45px;
    }
  }
`;

const Label = styled.label`
  font-size: 2.5rem;
  font-family: 'Oneer';
  text-transform: uppercase;
  display: inline-flex;
  flex-direction: column;
  max-width: 120px;
  line-height: 0.75;
  small {
    font-size: 1.5rem;
  }
  @media (max-width: 767px) {
    font-size: 2rem;
  }
  @media (max-width: 480px) {
    font-size: 1.75rem;
  }
  @media (max-width: 368px) {
    font-size: 1.5rem;
  }
`;

const Info = styled.div`
  font-family: Arial,Helvetica;
  font-size: 1rem;
  max-width: 500px;
  ul {
    list-style-position: inside;
    padding: 0;
    margin: 0.5rem;
  }
`;

const defaultQuantities = [0.5, 1, 1.5, 2];

const CustomerInfo = props => {
  const { name, quantity, handleChange, setQuantity } = props;
  return (
    <Wrapper>
      <div>
        <Label htmlFor="name">Nombre:</Label>
        <Input
          name="name"
          type="text"
          value={name}
          onChange={handleChange}
          autoComplete="off"
          placeholder="¿Para quién es este plato?"
          width="350px"
        />
      </div>
      <div>
        <Label htmlFor="quantity">Cantidad: <small>(Servicio)</small></Label>
          <Input
            name="quantity"
            type="number"
            value={quantity}
            onChange={handleChange}
            min="0.5"
            step="0.5"
            width="100px"
            required
          />
          {defaultQuantities.map(q => (
            <QuantitySelector key={q} value={q} setQuantity={setQuantity} quantity={quantity}>
              {q}
            </QuantitySelector>
          ))}
      </div>
      <Info>
        <ul>
          <li>(0.5) servicio de sushi, viene cortado en 5 bocadillos</li>
          <li>(1) servicio de sushi, viene cortado en 10 bocadillos</li>
          <li>(1.5) servicios de sushi, viene cortado en 15 bocadillos</li>
        </ul>
      </Info>
    </Wrapper>
  );
};

// Un rollo de sushi, o sea un (1) servicio, viene cortado en 10 bocadillos, RD$250.00. Es común un pedido de un servicio y medio (1.5) lo cual serían 15 bocadillos a un costo de RD$375.00; piden también medio servicio al costo de RD$125.00 pesos y así sucesivamente!

export default CustomerInfo;
