export function formatPrice(price) {
  if (!price) return;

  return price.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  });
}

// Function to check if User Agent is a mobile device
export const isMobile = /iPhone|iPad|iPod|Android|IEMobile|Opera Mini/i.test(navigator.userAgent);

// Check if restaurant is open
// const now = new Date(); // current Date that gives us current Time also

function getStartTime(now, startTime) {
  const s = startTime.split(':');
  return new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate(),
    parseInt(s[0], 0),
    parseInt(s[1], 0),
    parseInt(s[2], 0)
  );
}

function getEndTime(now, endTime) {
  const e = endTime.split(':');
  return new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate(),
    parseInt(e[0], 0),
    parseInt(e[1], 0),
    parseInt(e[2], 0)
  );
}

export function isBetweenDateTime(startTime, endTime) {
  const now = new Date();
  return now >= getStartTime(now, startTime) && now <= getEndTime(now, endTime)
}

export const isRestaurantOpen = (account) => {
  let startTime = '11:00:00';
  let endTime = '21:30:00';
  if(account) {
    if(account.open) startTime = account.open
    if(account.closed) endTime = account.closed
  }
  if(process.env.NODE_ENV !== 'development') {
    return isBetweenDateTime(startTime, endTime);
  } else {
    return true
  }
};

// export const isRestaurantOpen = isOpen;