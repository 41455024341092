export const sodas_brands = [
  "Coca Cola",
  "Coca Cola Zero",
  "Sprite",
  "Rojo",
  "Uva",
  "Merengue",
  "Naranja",
];

export const juice_flavors = ["CHINOLA", "CEREZA", "LIMON"];

export const drinks = [
  {
    id: "agua",
    key: "agua",
    title: "Agua",
    display: "AGUA",
    category: "bebida",
    price: 50,
    quantity: 0,
  },
  {
    id: "agua-con-gas",
    key: "agua-con-gas",
    title: "Agua con gas",
    display: "AGUA CON GAS",
    category: "bebida",
    price: 70,
    quantity: 0,
  },
  {
    id: "Coca Cola",
    key: "refresco",
    title: "Coca Cola",
    display: "REFRESCO",
    category: "bebida",
    price: 70,
    quantity: 0,
  },
  {
    id: "CHINOLA",
    key: "jugo",
    title: "CHINOLA",
    display: "JUGO",
    category: "bebida",
    price: 120,
    quantity: 0,
  },
  // {
  //   id: 'redbull',
  //   key: 'redbull',
  //   title: 'Redbull',
  //   display: 'REDBULL',
  //   category: 'bebida',
  //   price: 100,
  //   quantity: 0
  // },
  // {
  //   id: 'cerveza',
  //   key: 'cerveza',
  //   title: 'Cerveza',
  //   display: 'CERVEZA',
  //   category: 'bebida',
  //   price: 150,
  //   quantity: 0
  // },
  {
    id: "cerveza-modelo",
    key: "cerveza",
    title: "Cerveza Gran Modelo 24oz (2 Vasos)",
    display: "CERVEZA GRAN MODELO",
    category: "bebida",
    price: 350,
    quantity: 0,
  },
  {
    id: "cerveza-presidente",
    key: "cerveza-presidente",
    title: "Cerveza Presidente",
    display: "CERVEZA PRESIDENTE",
    category: "bebida",
    price: 325,
    quantity: 0,
  },
];

// export const refrescos = [
//   'Coca Cola',
//   'Coca Cola Light',
//   'Sprite',
//   'Rojo',
//   'Uva',
//   'Merengue',
//   'Fanta',
// ]

export const desserts = [
  // {
  //   id: "flan",
  //   key: "flan",
  //   title: "Flan",
  //   display: "FLAN",
  //   category: "postre",
  //   price: 125,
  //   quantity: 0,
  // },
  // {
  //   id: "brownieTwix",
  //   key: "brownieTwix",
  //   title: "Brownie Twix",
  //   display: "BROWNIE TWIX",
  //   category: "postre",
  //   price: 150,
  //   quantity: 0,
  // },
  {
    id: "brownieTwix",
    key: "brownieTwix",
    display: "BROWNIES",
    title: "Brownies",
    category: "postre",
    price: 125,
    quantity: 0,
  },
  {
    id: "tresleches",
    key: "tresleches",
    title: "Blondie de brown butter y dulce de leche con topping",
    display: "BLONDIE DE BROWN BUTTER Y DULCE DE LECHE",
    category: "postre",
    price: 150,
    quantity: 0,
  },
  {
    id: "nutellaCake",
    key: "nutellaCake",
    title: "Lime cheesecake",
    display: "LIME CHEESECAKE",
    category: "postre",
    price: 200,
    quantity: 0,
  },
  {
    id: "cookie",
    key: "cookie",
    title: "Galleta de limón, pistacho y choco blanco",
    display: "GALLETA DE LIMON PISTACHO Y CHOCO BLANCO",
    category: "postre",
    price: 320,
    quantity: 0,
  },
  // {
  //   id: 'nutellaCake',
  //   key: 'nutellaCake',
  //   display: 'NUTELLA CAKE',
  //   title: 'Nutella Cake',
  //   category: 'postre',
  //   price: 200,
  //   quantity: 0
  // },
  // {
  //   id: 'tresleches',
  //   key: 'tresleches',
  //   display: 'TRES LECHES',
  //   title: 'Tres Leche',
  //   category: 'postre',
  //   price: 250,
  //   quantity: 0
  // },
];
