import React from 'react';
import styled from 'styled-components';

import Checkbox from '../ItemForm/Checkbox';
import { SectionLabel, Disabled } from '../styled';

import { surpriseType, surpriseDetails } from '../../util/surprise';
import img from './surpriseBackground.png';
import theme from '../styled/theme';

const Wrapper = styled.aside`
  background: #fff23e;
  cursor: not-allowed;
  width: 600px;
  height: 600px;
  overflow: hidden;
  position: absolute;
  transform: rotate(-20deg);
  top: -100px;
  right: -100px;
  z-index: 9;

  &::before {
    background-image: url(${img});
    background-repeat: no-repeat;
    background-size: 40%;
    content: '';
    display: block;
    height: 100%;
    right: -210px;
    top: 200px;
    position: absolute;
    position: absolute;
    transform: rotate(20deg);
    width: 100%;
  }

  @media (max-width: 1000px) {
    display: none;
  }
`;

const Content = styled.div`
  color: ${theme.colors.primary};
  position: absolute;
  top: 22.5%;
  right: 25%;
  transform: rotate(20deg);
  opacity: 0.25;
`;

const SurpriseType = styled.div`
  border-bottom: 4px dotted #bdbcbc;
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

const SurpriseDetails = styled.div`
  label {
    flex-direction: row-reverse;
  }
`;

const Surprise = () => (
  <Wrapper>
    <SectionLabel style={{ opacity: 0.25 }}>PLATO SOrPrESA</SectionLabel>
    <Content>
      <SurpriseType>
        {surpriseType.map(({ key, category, display }) => (
          <Checkbox key={key} id={key} type="radio" value={key} name={category} disabled>
            {display}
          </Checkbox>
        ))}
      </SurpriseType>
      <SurpriseDetails>
        {surpriseDetails.map(({ key, category, display }) => (
          <Checkbox key={key} id={key} value={key} type="checkbox" disabled>
            {display}
          </Checkbox>
        ))}
      </SurpriseDetails>
    </Content>
    <Disabled top="50%" left="40%">
      Proximamente
    </Disabled>
  </Wrapper>
);

export default Surprise;
