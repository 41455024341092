import React from 'react';
import Popup from 'reactjs-popup';
import styled from 'styled-components';

import theme from '../../styled/theme';

const Wrapper = styled.div`
  padding: 0 2rem 2rem;
  text-transform: uppercase;
  font-family: "Oneer";
  h3 {
    color: ${theme.colors.primary};
    margin: 1rem 0 0;
    font-size: 46.8px;
  }

  p {
    margin: 0 0 1.5rem;
  }

  li {
    font-size: 40px;
  }
  @media (max-width: 767px) {
    font-size: 1.75rem;
    h3 {
      font-size: 32.76px;
    }
    li {
      font-size: 28px;
    }
  }
`;

const Close = styled.a`
  cursor: pointer;
  padding-left: 1rem;
  text-align: right;
  font-size: 40px;
`;

const Errors = props => (
  <Popup open={props.open} onClose={props.onClose} closeDocumentonClick>
    <div>
      <Close onClick={props.close}>&times;</Close>
      <Wrapper>
        <h3>Espera!</h3>
        <ul>
          {Object.keys(props.formErrors).map((fieldName, i) => {
            if (props.formErrors[fieldName].length > 0) {
              return <li key={i}>{props.formErrors[fieldName]}</li>;
            }
            return '';
          })}
        </ul>
      </Wrapper>
    </div>
  </Popup>
);

export default Errors;
