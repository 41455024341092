export const wrappings = [
  {
    key: 'arrozAlga',
    title: 'Arroz Por Fuera',
    display: 'ArrOZ POr FUErA',
    subtitle: '(CON ALGA)',
    category: 'rollo',
    price: 320,
  },
  {
    key: 'algaArroz',
    title: 'Alga Por Fuera',
    display: 'ALGA POr FUErA',
    subtitle: '(CON ArrOZ)',
    category: 'rollo',
    price: 320,
  },
  {
    key: 'alga',
    title: 'Solo Alga',
    display: 'SOLO ALGA',
    subtitle: '(SIN ArrOZ)',
    category: 'rollo',
    price: 320,
  },
  {
    key: 'arroz',
    title: 'Solo Arroz',
    display: 'SOLO ArrOZ',
    subtitle: '(SIN ALGA)',
    category: 'rollo',
    price: 320,
  },
];

export const wrappingsSpecial = [
  {
    key: 'papel',
    title: 'Papel de Arroz',
    display: 'PAPEL DE ArrOZ',
    subtitle: '',
    category: 'rollo',
    price: 450,
  },
  {
    key: 'pescado',
    title: 'Pescado',
    display: 'PESCADO',
    subtitle: '(SIN ArrOZ)',
    category: 'rollo',
    price: 700,
  },
  {
    key: 'platano',
    title: 'Platano',
    display: 'PLATANO',
    subtitle: '(SIN ArrOZ)',
    category: 'rollo',
    price: 425,
  },
];

export const wrappingsExtraSpecial = [
  {
    key: 'empanizado',
    title: 'Empanizado',
    display: 'EMPANIZADO',
    // subtitle: 'Tu RoLLO',
    category: 'rollo',
    price: 395,
  },
];

export const bowls = [
  {
    key: 'bowl',
    title: 'Bowl',
    category: 'bowls',
    price: 650,
  },
];

export const bowlOptions = [
  {
    key: 'bowlAlga',
    title: 'Alga',
    subtitle: 'Alga',
    display: 'ALGA',
    category: 'bowlOptions',
  },
  {
    key: 'bowlArroz',
    title: 'Arroz',
    subtitle: 'Arroz',
    display: 'ArrOZ',
    category: 'bowlOptions',
  },
];

export const temakis = [
  {
    key: 'temakiAlgaArroz',
    title: 'Alga y Arroz',
    display: 'ALGA ArrOZ',
    category: 'cono',
    price: 275,
  },
  {
    key: 'temakiArroz',
    title: 'Papel de Arroz',
    display: 'PAPEL&nbsp;<small>DE</small><br>ArrOZ',
    category: 'cono',
    price: 275,
  },
];
