import React from 'react';
import styled from 'styled-components';
import MediaQuery from 'react-responsive';

import { AppContext } from './AppProvider';
import Logo from './Logo';
import ItemForm from './ItemForm';
import Surprise from './Surprise';
import Header from './Header';
import { Layout } from './styled';

const Wrapper = styled.div`
  @media (min-width: 768px) {
    padding-top: 20rem;
  }
`;

const App = props => (
  <div>
    <MediaQuery maxDeviceWidth={767}>
      <Header />
    </MediaQuery>
    <Layout>
      <Wrapper>
        <MediaQuery minDeviceWidth={768}>
          <Logo width="600px" />
        </MediaQuery>
        <AppContext.Consumer>
          {context => (
            <React.Fragment>
              <Surprise context={context} />
              <ItemForm context={context} history={props.history} />
            </React.Fragment>
          )}
        </AppContext.Consumer>
      </Wrapper>
    </Layout>
  </div>
);

export default App;
