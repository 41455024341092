import React from 'react';
import styled from 'styled-components';

import Logo from '../Logo/';
import { ButtonLink } from '../styled';

const Wrapper = styled.section`
  text-align: center;
  height: calc(100vh - 5rem);
`;

const Box = styled.div`
  background: rgba(255, 255, 255, 0.8);
  border-radius: 5px;
  box-shadow: 0 0 8px rgba(57, 56, 56, 0.35);
  margin: -2rem auto 0;
  padding: 8rem 0;
  position: relative;
  transform: rotate(-5deg) translateY(-35%);
  top: 50%;
  width: 650px;
`;

const Title = styled.div`
  font-size: 6rem;
  margin-bottom: 3rem;
  line-height: 4.75rem;
`;

const StyledButton = styled(ButtonLink)`
  font-size: 3rem;
  padding: 1rem 3.5rem;
`;

const Home = () => (
  <Wrapper>
    <Logo size="600px" />
    <Box>
      <Title>
        OOPS!<br />
        ESTA PAGINA NO EXISTE
      </Title>
      <StyledButton bg="primary" groovy to="/ordenar/">
        Volver Atras
      </StyledButton>
    </Box>
  </Wrapper>
);

export default Home;
