import React from 'react';
import styled from 'styled-components';

import { DollarSign } from '../styled/';
import theme from '../styled/theme';

const Wrapper = styled.div`
  border: 5px dotted ${theme.colors.grayDark};
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: ${props => (props.groovy ? 'Chunq' : 'Oneer')};
  text-align: center;
  margin: 1rem 0;
  padding: 0.5rem;

  h2 {
    font-size: ${props => (props.small ? '35px' : '60px')};
    color: ${props => (props.color ? props.color : theme.colors.grayDark)};
    line-height: 0.75;
    margin: 0;
    position: relative;
    text-transform: uppercase;

    small {
      bottom: 10px;
      font-size: 0.5em;
      position: absolute;
      margin-left: 10px;
    }
  }

  h5 {
    font-family: Arial, Helvetica;
    font-size: 1rem;
    color: #84807c;
    text-transform: uppercase;
    margin: 0;
  }

  h3 {
    color: ${theme.colors.grayDark};
    font-size: 40px;
    margin: 0 1rem 0 0;
    position: absolute;
    text-transform: uppercase;
    transform: translateX(-120%);

    span,
    .dollar {
      font-size: 20px;
      text-transform: none;
    }
  }

  div {
    display: flex;
  }
`;

const SectionHead = props => (
  <Wrapper small={props.small} color={props.color} groovy={props.groovy}>
    <div>
      {props.price && (
        <h3>
          <span>rd</span>
          <DollarSign>$</DollarSign>
          {props.price}
        </h3>
      )}
      <h2>
        {props.children}
        {props.subtitle && <small>{props.subtitle}</small>}
      </h2>
    </div>
    <h5>{props.description}</h5>
  </Wrapper>
);

export default SectionHead;
