import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

import theme from '../styled/theme';
import { formatPrice } from '../../helpers';

const Title = styled.div`
  text-transform: capitalize;
`;

const Subtitle = styled.div`
  color: gray;
  font-size: 0.85rem;
  word-break: break-word;
`;

const DeleteButton = styled(FontAwesomeIcon)`
  color: ${theme.colors.gray};
  font-size: 0.825rem;
  cursor: pointer;
`;

const Wrapper = styled.div`
  border-bottom: 1px solid ${theme.colors.grayLight};
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  padding: 1rem;

  > div:first-child {
    flex: 0 1 6%;
    font-size: 0.85rem;
    margin-right: 1rem;
  }

  > div:nth-child(2) {
    flex-grow: 1;
  }

  > div:nth-child(3) {
    // flex-grow: 0.25;
    width: 15px;
  }
  > div:nth-child(4) {
    min-width: 67.31px;
    text-align: right;
  }
`;

const OrderSummaryItem = props => {
  const { data, canEdit } = props;
  const total = data.price * parseFloat(data.quantity);

  return (
    <Wrapper>
      <div>{data.quantity}</div>
      <div>
        <Title>
          {data.category} {data.name && `(${data.name})`}
        </Title>
        <Subtitle>{data.title}</Subtitle>
      </div>
      <div>{canEdit && <DeleteButton icon={faTrash} onClick={() => props.removeItemExtra(data)} />}</div>
      <div>
        <Title>{formatPrice(total)}</Title>
      </div>
    </Wrapper>
  );
};

OrderSummaryItem.propTypes = {
  removeItemExtra: PropTypes.func,
  canEdit: PropTypes.bool,
};

export default OrderSummaryItem;
