import axios from 'axios'
// create an axios instance
const service = axios.create({
  baseURL: 'https://admin.bambansushi.com/api/', // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 1000*60 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    config.headers["X-Token"] = '';
    config.headers["Pragma"] = "no-cache";

    return config;
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
    const {data} = response
    if(data.msg) {
    }

    return data;
  },
  (error) => {
    console.log(error.response)
    if(error.response) {
      const {data, status} = error.response
      if(status !== 404) {
        if(data.msg) {
        }
      }
      return Promise.reject(data);
    } else {
      return Promise.reject(error);      
    }
  }
);

export default service
