import React from 'react';
import styled from 'styled-components';
import check from './check.png';

const Image = styled.img`
  position: absolute;
  right: ${props => (props.reverse ? 'auto' : '20px')};
  left: ${props => (props.reverse ? '20px' : 'auto')};
  ${'' /* left: ${props => (props.left ? '20px' : 'auto')};
  right: ${props => (props.right ? '20px' : 'auto')}; */};
`;

const Check = props => <Image src={check} height={props.height} reverse={props.reverse} alt="Checkmark" />;

export default Check;
