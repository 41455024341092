import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingBasket } from '@fortawesome/free-solid-svg-icons';
import theme from '../styled/theme';

const Wrapper = styled.header`
  color: white;
  position: relative;
`;

const OrderCount = styled.div`
  background: ${theme.colors.primary};
  border-radius: 10px;
  color: white;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans',
    'Helvetica Neue', sans-serif;
  font-size: 10px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
  position: absolute;
  bottom: -10px;
  right: -5px;
`;

const ShoppingBagButton = props => (
  <Wrapper>
    <Link to="/ordenar/checkout">
      <FontAwesomeIcon color="white" icon={faShoppingBasket} size={props.size} />
      {props.order > 0 && <OrderCount>{props.order}</OrderCount>}
    </Link>
  </Wrapper>
);

export default ShoppingBagButton;
