import React from "react";
import Popup from "reactjs-popup";
import styled from "styled-components";
import { Button } from "../../styled/index";
import theme from "../../styled/theme";

const Wrapper = styled.div`
  padding: 0 2rem 2rem;
  text-transform: uppercase;

  h3 {
    color: ${theme.colors.primary};
    margin: 1rem 0 0;
  }

  p {
    margin: 0 0 1.5rem;
  }

  .ModalButton {
    display: flex;
    justify-content: flex-end;
  }

  @media (max-width: 767px) {
    font-size: 1.75rem;
  }
`;

const Close = styled.a`
  cursor: pointer;
  padding-left: 1rem;
  text-align: right;
`;

const Errors = (props) => {
  return (
    <Popup open={props.open} onClose={props.onClose} closeDocumentonClick>
      <div>
        <Close onClick={props.close}>&times;</Close>
        <Wrapper>
          <h3>Espera!</h3>
          <p>{props.subtitle}</p>
          <ul>
            {Object.keys(props.formErrors).map((fieldName, i) => {
              if (props.formErrors[fieldName].length > 0) {
                return <li key={i}>{props.formErrors[fieldName]}</li>;
              }
              return "";
            })}
          </ul>
          {props.btn && 
            <div class="ModalButton">
              <Button  mr="1rem" bg="green" onClick={props.reset}>{props.btn}</Button>
              <Button bg="primary" onClick={props.close}>Cerrar</Button>
            </div>
          }
        </Wrapper>
      </div>
    </Popup>
  );
};

export default Errors;
