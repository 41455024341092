import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { InputSquare, DollarSign, Disabled, Canceled } from '../../styled';
import check from '../Ingredient/check.png';
import theme from '../../styled/theme';

const Wrapper = styled.div`
  color: ${props => (props.dark ? theme.colors.grayDark : 'white')};
  display: flex;
  color: ${theme.colors.grayDark};
  align-items: center;
  font-family: 'Oneer';
  font-size: 2.75rem;
  line-height: 2.5rem;
  text-align: right;
  white-space: ${props => (props.whiteSpace ? props.whiteSpace : 'nowrap')};
  position: relative;

  @media (max-width: 768px) {
    font-size: 2.25rem;
  }

  label {
    cursor: pointer;
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-column-gap: 10px;
    height: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    width: 100%;

    @media (max-width: 768px) {
      padding: 0.5rem 1rem;

      > img {
        height: 60px;
      }
    }
  }

  input {
    visibility: hidden;
  }

  &.wrapperHidden {
    display: none;
  }
`;

const Subtitle = styled.div`
  font-size: 0.75em;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Price = styled.span`
  color: ${theme.colors.primary};

  span:first-child {
    color: ${theme.colors.grayDark};
  }
`;

const Wrapping = props => {
  const { data, image, id, whiteSpace, setWrapping, selectedData, showPrice } = props;
  const imageUrl = image && `${process.env.PUBLIC_URL}/img/${image}.png`;
  const isSelected = selectedData.wrapping.key && selectedData.wrapping.key === id;
  return (
    <Wrapper className={data.closed ? 'wrapperHidden' : ''} whiteSpace={whiteSpace}>
      <label htmlFor={id}>
        <div>{image && <img src={imageUrl} width="auto" height="75" alt={data.title} />}</div>
        <div>
          {data.display}
          <Subtitle>
            <InputSquare>{isSelected && <img src={check} height="20" alt="check" />}</InputSquare>
            <input
              id={id}
              name="wrapping"
              type="radio"
              value={id}
              onChange={() => setWrapping(data)}
              disabled={data.disabled}
            />
            {data.subtitle && data.subtitle}
            {data.price &&
              showPrice && (
                <Price>
                  <span>rd</span>
                  <DollarSign>$</DollarSign>
                  {data.price}
                </Price>
              )}
          </Subtitle>
          {data.disabled && (
            <Disabled top="0" left="50%">
              Proximamente
            </Disabled>
          )}
          {data.closed && (
            <Canceled top="0" left="50%">
              Disponible de <br></br><span>11:00AM a 7:00PM</span>
            </Canceled>
          )}
        </div>
      </label>
    </Wrapper>
  );
};

Wrapping.defaultProps = {
  showPrice: true,
};

Wrapping.propTypes = {
  data: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  image: PropTypes.string,
  id: PropTypes.string,
  whiteSpace: PropTypes.string,
  setWrapping: PropTypes.func,
  showPrice: PropTypes.bool,
};

export default Wrapping;
