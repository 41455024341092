import React from 'react';
import styled from 'styled-components';

import { InputSquare } from '../../styled';
import check from '../Ingredient/check.png';
import theme from '../../styled/theme';

const Wrapper = styled.div`
  color: ${props => (props.dark ? theme.colors.grayDark : 'white')};
  display: flex;
  color: ${theme.colors.grayDark};
  align-items: center;
  font-family: 'Oneer';
  font-size: 2.5rem;
  flex: 0 1 50%;
  line-height: 2.5rem;
  text-align: right;

  &:first-child {
    border-right: 4px dotted black;
  }

  &:last-child > label {
    flex-flow: row-reverse nowrap;
  }

  label {
    cursor: pointer;
    display: flex;
    flex-flow: row nowrap;
    height: 100%;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    width: 100%;
  }

  input {
    display: none;
  }
`;

const getDisplayName = display => ({ __html: display });

const Temaki = props => {
  const { data, image, id, selectedData, setWrapping } = props;
  const imageUrl = image && `${process.env.PUBLIC_URL}/img/${image}.png`;
  const isSelected = selectedData.wrapping.key && selectedData.wrapping.key === id;
  return (
    <Wrapper>
      <label htmlFor={id}>
        <div dangerouslySetInnerHTML={getDisplayName(data.display)} />
        <div>
          <InputSquare>{isSelected && <img src={check} height="20" alt="check" />}</InputSquare>
          <input id={id} name="wrapping" type="radio" value={id} onChange={() => setWrapping(data)} />
        </div>
        <div>{image && <img src={imageUrl} width="auto" height="75" alt={data.title} />}</div>
      </label>
    </Wrapper>
  );
};

export default Temaki;
