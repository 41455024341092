import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { InputSquare } from '../../styled';
import check from '../Ingredient/check.png';

const Wrapper = styled.div`
  label {
    align-items: center;
    display: flex;
  }

  input {
    display: none;
  }
`;

const Checkbox = props => {
  const { children, id, type, value, name, handleChange, checked } = props;
  return (
    <Wrapper>
      <label htmlFor={id}>
        <InputSquare circle={type === 'radio'}>{checked && <img src={check} height="20" alt="check" />}</InputSquare>
        <input id={id} type={type} value={value} name={name} onChange={handleChange} />
        {children}
      </label>
    </Wrapper>
  );
};

Checkbox.propTypes = {
  children: PropTypes.string,
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  value: PropTypes.string,
  name: PropTypes.string,
  handleChange: PropTypes.func,
  checked: PropTypes.bool,
};

export default Checkbox;
