import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { injectGlobal, ThemeProvider } from 'styled-components';
import Raven from 'raven-js';
import registerServiceWorker from './registerServiceWorker';

import AppProvider from './components/AppProvider';
import App from './components/App';
import Home from './components/Home/';
import Checkout from './components/Checkout/';
import Confirmation from './components/Checkout/Confirmation';
import NotFound from './components/NotFound';
import { isRestaurantOpen } from './helpers';

import theme from './components/styled/theme';

import Oneer from './static/fonts/Oneer.ttf';
import Chunq from './static/fonts/Chunq.ttf';

if (process.env.NODE_ENV !== 'development') {
  Raven.config('https://66c5381e88814b0a9a3ed7de302813eb@sentry.io/1244190').install();
}

injectGlobal`
  * {
    box-sizing: border-box;
  }

  body {
    background: ${theme.colors.icecream};
    font-family: "Oneer";
    font-size: 40px;
    color: ${theme.colors.grayDark};
    margin: 0;
  }

  @media (max-width: 767px) {
    .popup-content {
      width: 90% !important;
    }
  }

  @font-face {
    font-family: 'Oneer';
    font-style: normal;
    font-weight: 400;
    src: url(${Oneer}) format('truetype');
  }

  @font-face {
    font-family: 'Chunq';
    font-style: normal;
    font-weight: 400;
    src: url(${Chunq}) format('truetype');
  }
`;

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isRestaurantOpen ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/',
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

ReactDOM.render(
  <AppProvider>
    <ThemeProvider theme={theme}>
      <Router>
        <div>
          <Switch>
            <Route exact path="/" component={Home} />
            <PrivateRoute exact path="/ordenar" component={App} />
            <PrivateRoute exact path="/ordenar/checkout" component={Checkout} />
            <PrivateRoute exact path="/ordenar/confirmacion" component={Confirmation} />
            {/* <PrivateRoute exact path="/resumen" component={OrderSummaryPage} /> */}
            <Route component={NotFound} />
          </Switch>
        </div>
      </Router>
    </ThemeProvider>
  </AppProvider>,
  document.getElementById('root')
);
registerServiceWorker();
