import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from '../../styled/theme';

const Button = styled.a`
  background: ${props => (props.selected ? '#88281D' : 'transparent')};
  border: 2px solid ${props => (props.selected ? '#88281D' : theme.colors.grayDark)};
  border-radius: 50%;
  box-shadow: ${props => (props.selected ? '2px 0 4px rgba(0, 0, 0, 0.25)' : 'none')};
  color: ${props => (props.selected ? 'white' : 'inherit')};
  cursor: pointer;
  display: inline-block;
  font-family: Arial, sans-serif;
  font-size: 1rem;
  font-weight: bold;
  height: 40px;
  margin: 0 0.25rem;
  line-height: 40px;
  text-align: center;
  vertical-align: baseline;
  width: 40px;

  @media (max-width: 767px) {
    height: 32px;
    width: 32px;
    line-height: 32px;
    font-size: 0.75rem;
  }
`;

const QuantitySelector = props => (
  <Fragment>
    <Button selected={props.quantity === props.value} onClick={() => props.setQuantity(props.value)}>
      {props.children}
    </Button>
  </Fragment>
);

QuantitySelector.propTypes = {
  value: PropTypes.number,
};

export default QuantitySelector;
