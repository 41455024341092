import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { DollarSign, Disabled } from "../../styled";
import theme from "../../styled/theme";
import { sodas_brands, juice_flavors } from "../../../../src/util/extras";

const Extra = styled.div`
  color: #373535;
  display: flex;
  align-items: center;
  font-family: "Oneer";
  font-size: 2.25rem;
  line-height: 2.5rem;
  text-align: left;
  white-space: nowrap;
  position: relative;
  /* max-width: 270px; */
  white-space: break-spaces;
  line-height: 1;
  word-break: break-word;
  label {
    cursor: pointer;
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-column-gap: 10px;
    height: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 0;
    width: 100%;

    img {
      display: block;
      max-width: 70px;
    }

    .labelImage {
      display: block;
      max-width: 70px;
      min-width: 70px;
      padding-right: 0;
      object-fit: contain;
    }
  }

  @media (max-width: 970px) {
    label {
      font-size: 1.75rem;
      img {
        max-width: 50px;
      }
    }
  }
  @media (max-width: 768px) {
    font-size: 2.25rem;
    label {
      padding: 0.5rem 0rem;
      font-size: 1.75rem;
      > img {
        height: 60px;
      }
    }

  }
`;

const Subtitle = styled.div`
  font-size: 0.75em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const Price = styled.span`
  color: ${theme.colors.primary};
  font-size: 1.75rem;
  padding-left: 0.25rem;
  span:first-child {
    color: ${theme.colors.grayDark};
  }
`;

const InputNumber = styled.div`
  display: flex;

  > input {
    background: #faf6f2;
    border: 1px solid #fdfdfc;
    border-radius: 8px;
    font-size: 1rem;
    padding: 1rem;
    width: 50px;
    box-sizing: border-box;
    text-align: center;
    color: #a10204;
    &:disabled {
      color: #a10204 !important;
      opacity: 1 !important;
    }
  }

  .arrow-top,
  .arrow-bottom {
    padding: 2px 5px;
    background: #faf6f2;
    height: 100%;
    display: flex;
    align-items: center;
    border-radius: 8px;
  }
  .arrow-top {
    margin-bottom: 1px;
    img {
      width: 20px;
    }
  }
  .arrow-bottom {
    margin-top: 1px;
    img {
      width: 20px;
    }
  }
  .arrows-number {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
`;

const Sodas = styled.div`
  padding: 0rem 1rem;
  display: flex;
  margin-bottom: 0.5rem;
  .soda_key {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FAF6F2;
    font-size: 1.25rem;
    margin-right: 0.25rem;
  }
  .soda_select {
    width: 100%
    background: #FAF6F2;
    border: none;
  }
`;

const Display = styled.div`
  font-size: 32px;
  @media (max-width: 768px) {
    font-size: 26px;
  }
`
const Juices = styled.div`
  padding: 0rem 1rem;
  display: flex;
  margin-bottom: 0.5rem;
  .juice_key {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FAF6F2;
    font-size: 1.25rem;
    margin-right: 0.25rem;
  }
  .juice_select {
    width: 100%
    background: #FAF6F2;
    border: none;
  }
`;

const Wrapping = (props) => {
  const {
    data,
    image,
    id,
    whiteSpace,
    showPrice,
    sodas,
    juices,
    setExtra,
  } = props;
  const imageUrl = image && `${process.env.PUBLIC_URL}/img/${image}.png`;
  // const isSelected = selectedData.wrapping.key && selectedData.wrapping.key === id;
  // const isSelected = false;

  const handleSodaChange = async ({ target: { name, value } }, index, soda) => {
    setExtra(soda, index, value);
  };
  const handleJuiceChange = async (
    { target: { name, value } },
    index,
    juice
  ) => {
    setExtra(juice, index, value);
  };

  const handleArrowClick = async (bool) => {
    console.log("data", bool);
    if (bool) {
      ++data.quantity;
      if (data.key === "refresco") {
        data.quantity = 1;
      }
      if (data.key === "jugo") {
        data.quantity = 1;
      }
    } else {
      if (data.quantity === 0) return;
      --data.quantity;
      if (data.key === "refresco") {
        data.quantity = 0;
      }
      if (data.key === "jugo") {
        data.quantity = 0;
      }
    }
    setExtra(Object.assign({}, data));
  };

  return (
    <div>
      <Extra whiteSpace={whiteSpace}>
        <label htmlFor={id}>
          <div>
            {image && (
              <img
                className={"labelImage"}
                src={imageUrl}
                width="auto"
                height="75"
                alt={data.title}
              />
            )}
          </div>
          <div>
            <Display>{data.display}</Display>
            <Subtitle>
              {/* <InputSquare>{isSelected && <img src={check} height="20" alt="check" />}</InputSquare> */}
              <InputNumber>
                <input id={id} name={data.key} value={data.quantity} disabled />
                <div className="arrows-number">
                  <div
                    className="arrow-top"
                    onClick={(event) => handleArrowClick(true)}
                  >
                    <img
                      src={process.env.PUBLIC_URL + "/img/arrow-top.svg"}
                      alt="arrow top"
                    />
                  </div>
                  <div
                    className="arrow-bottom"
                    onClick={(event) => handleArrowClick(false)}
                  >
                    <img
                      src={process.env.PUBLIC_URL + "/img/arrow-bottom.svg"}
                      alt="arrow bottom"
                    />
                  </div>
                </div>
              </InputNumber>
              {data.price && showPrice && (
                <Price>
                  <span>rd</span>
                  <DollarSign>$</DollarSign>
                  {data.price}
                </Price>
              )}
            </Subtitle>
            {data.disabled && (
              <Disabled top="0" left="50%">
                Proximamente
              </Disabled>
            )}
          </div>
        </label>
      </Extra>
      {data.key === "refresco" &&
        sodas.map((soda, index) => {
          return (
            <Sodas key={index + 1}>
              <div className="soda_key">{index + 1}</div>
              <select
                className="soda_select"
                onChange={(event) => handleSodaChange(event, index, soda)}
                value={soda.title}
              >
                {sodas_brands.map((brand, index) => {
                  return <option key={index}>{brand}</option>;
                })}
              </select>
            </Sodas>
          );
        })}
      {data.key === "jugo" &&
        juices.map((juice, index) => {
          return (
            <Juices key={index + 1}>
              <div className="juice_key">{index + 1}</div>
              <select
                className="juice_select"
                onChange={(event) => handleJuiceChange(event, index, juice)}
                value={juice.title}
              >
                {juice_flavors.map((brand, index) => {
                  return <option key={index}>{brand}</option>;
                })}
              </select>
            </Juices>
          );
        })}
    </div>
  );
};

Wrapping.defaultProps = {
  showPrice: true,
};

Wrapping.propTypes = {
  data: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array])
  ),
  image: PropTypes.string,
  id: PropTypes.string,
  whiteSpace: PropTypes.string,
  setWrapping: PropTypes.func,
  showPrice: PropTypes.bool,
};

export default Wrapping;
