export const proteins = [
  {
    key: 'atun',
    title: 'Atun',
    display: 'ATUN',
    category: 'proteins',
  },
  {
    key: 'salmon',
    title: 'Salmon',
    display: 'SALMON',
    category: 'proteins',
  },
  {
    key: 'dorado',
    title: 'Dorado al Limon',
    display: 'DOrADO<br>AL LIMoN',
    category: 'proteins',
  },
  {
    key: 'camaron',
    title: 'Camaron',
    display: 'CAMArON',
    category: 'proteins',
  },
  {
    key: 'camaronPanko',
    title: 'Camaron Panko',
    display: 'CAMArON<br>PANKO',
    category: 'proteins',
  },
  {
    key: 'kingCrab',
    title: 'King Crab',
    display: 'KING<br>CrAB',
    category: 'proteins',
  },
  {
    key: 'surimi',
    title: 'Surimi',
    display: 'SUrIMI',
    category: 'proteins',
  },
  {
    key: 'pollo',
    title: 'Pollo',
    display: 'POLLO',
    category: 'proteins',
  },
];

export const sauces = [
  {
    key: 'honeyMayo',
    title: 'Honey Mayo',
    display: 'HONEY MAYO',
    category: 'sauces',
  },
  {
    key: 'anguila',
    title: 'Anguila',
    display: 'ANGUILA',
    category: 'sauces',
  },
  {
    key: 'spicyMayo',
    title: 'Spicy Mayo',
    display: 'SPICYMAYO',
    category: 'sauces',
  },
  {
    key: 'ponzu',
    title: 'Ponzu',
    display: 'PONZU',
    category: 'sauces',
  },
  {
    key: 'wasabiMayo',
    title: 'Wasabi Mayo',
    display: 'WASABIMAYO',
    category: 'sauces',
  },
  {
    key: 'chimichurri',
    title: 'Chimichurri',
    display: 'CHIMICHUrrI',
    category: 'sauces',
  },
  {
    key: 'cilantroMayo',
    title: 'Cilantro Mayo',
    display: 'CILANTROMAYO',
    category: 'sauces',
  },
  {
    key: 'thai',
    title: 'Thai/Coco',
    display: 'THAI/COCO',
    category: 'sauces',
  },
];

export const complements = [
  {
    key: 'creamCheese',
    title: 'Cream Cheese',
    display: 'CrEAM<br>CHEESE',
    category: 'complements',
  },
  {
    key: 'zanahoria',
    title: 'Zanahoria',
    display: 'ZANAHOrIA',
    category: 'complements',
  },
  {
    key: 'pepino',
    title: 'Pepino',
    display: 'PePINO',
    category: 'complements',
  },
  {
    key: 'esparrago',
    title: 'Esparrago',
    display: 'ESPArrAGO',
    category: 'complements',
  },
  {
    key: 'kimchi',
    title: 'Kimchi',
    display: 'KIMcHI',
    category: 'complements',
  },
  {
    key: 'puerro',
    title: 'Puerro',
    display: 'PUErrO',
    category: 'complements',
  },
  {
    key: 'aguacate',
    title: 'Aguacate',
    display: 'AGuACAtE',
    category: 'complements',
  },
  {
    key: 'platanoMaduro',
    title: 'Platano Maduro',
    display: 'PLATANO<br><small>MADURo</small>',
    category: 'complements',
  },
  {
    key: 'bacon',
    title: 'Bacon',
    display: 'BACOn',
    category: 'complements',
  },
];

export const toppings = [
  {
    key: 'crunchy',
    title: 'Crunchy',
    display: 'CrUNCHY',
    category: 'toppings',
  },
  {
    key: 'furikake',
    title: 'Furikake',
    display: 'FUrIKAKE',
    category: 'toppings',
  },
  {
    key: 'tallarines',
    title: 'Tallarines',
    display: 'TALLArINES',
    category: 'toppings',
  },
  {
    key: 'brie',
    title: 'Queso Brie',
    display: 'QUESO<br>BrIE',
    category: 'toppings',
  },
  {
    key: 'tobiko',
    title: 'Tobiko',
    display: 'TOBIKO',
    category: 'toppings',
  },
  {
    key: 'pico',
    title: 'Pico de Gallo',
    display: 'PICO <small>DE</small><br>GALLO',
    category: 'toppings',
  },
];
