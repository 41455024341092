import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import theme from '../../styled/theme';
import Check from './Check';

const Wrapper = styled.div`
  background: ${props => (props.background ? `url('${process.env.PUBLIC_URL}/img/${props.background}.png')` : 'none')};
  background-size: cover;
  border-radius: 24px;
  border-bottom: ${props => (props.selected ? `6px solid ${theme.colors.grayDark}` : '0')};
  box-sizing: border-box;
  box-shadow: ${props => (props.selected ? '0 0 8px rgba(0, 0, 0, 0.5)' : 'none')};
  color: white;
  display: flex;
  align-items: center;
  font-family: 'Oneer';
  font-size: ${({ category }) => (category === 'proteins' || category === 'complements' ? '3.25rem' : '2.9rem')};
  line-height: 2.5rem;
  position: relative;
  margin-bottom: 0.75rem;
  height: ${props => (props.height ? props.height : '100px')};

  label {
    cursor: pointer;
    display: flex;
    height: 100%;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 1rem;
    width: 100%;
  }

  input {
    display: none;
  }
`;

class Ingredient extends Component {
  state = {
    selected: false,
  };

  getDisplayName = display => ({ __html: display });

  handleClick = () => {
    this.setState({
      selected: !this.state.selected,
    });
    return true;
  };

  render() {
    const { data, image, id, background, height, addIngredient, selectedData } = this.props;
    const imageUrl = image && `${process.env.PUBLIC_URL}/img/${image}.png`;
    const isSelected = selectedData[data.category].map(value => value.key).includes(id);

    return (
      <Wrapper background={background} selected={isSelected} height={height} category={data.category}>
        <Fragment>
          <label htmlFor={id}>
            <span dangerouslySetInnerHTML={this.getDisplayName(data.display || data.title)} />
            <div>
              {image && (
                <img src={imageUrl} width="auto" height={data.category === 'sauces' ? '100' : '75'} alt={data.title} />
              )}
            </div>
            <input
              id={id}
              name={id}
              type="checkbox"
              onChange={() => this.handleClick() && addIngredient(data.category, data)}
            />
            {isSelected && <Check height="50" />}
          </label>
        </Fragment>
      </Wrapper>
    );
  }
}

Ingredient.propTypes = {
  title: PropTypes.string,
  display: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  id: PropTypes.string,
  background: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  category: PropTypes.string,
  height: PropTypes.string,
  addIngredient: PropTypes.func,
};

export default Ingredient;
