import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import logo from './logo.png';
import ShoppingBagButton from './ShoppingBagButton';
import { AppContext } from '../AppProvider';

const Wrapper = styled.header`
  background: #231f20;
  display: flex;
  font-size: 1rem;
  align-items: center;
  justify-content: space-between;
  min-height: 60px;
  padding: 0.25rem 1rem;
`;

const Header = props => {
  function totalItems(order, orderExtra) {
    let total = 0
    order.concat(orderExtra).forEach(o => {
      total += o.quantity
    });
    return total
  }
  return (
    <AppContext.Consumer>
      {context => (
        <Wrapper>
          <Link to="/ordenar">
            <img src={logo} alt="Bamban Sushi" height="60px" />
          </Link>
          {!props.hideCart && <ShoppingBagButton size="2x" order={totalItems(context.order, context.orderExtra)} />}
        </Wrapper>
      )}
    </AppContext.Consumer>
  );
}

export default Header;
