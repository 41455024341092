import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

import theme from "../styled/theme";
import { formatPrice } from "../../helpers";

const Title = styled.div`
  text-transform: capitalize;
`;

const Subtitle = styled.div`
  color: gray;
  font-size: 0.85rem;
  word-break: break-word;
`;

const DeleteButton = styled(FontAwesomeIcon)`
  color: ${theme.colors.gray};
  font-size: 0.825rem;
  cursor: pointer;
`;

const Wrapper = styled.div`
  border-bottom: 1px solid ${theme.colors.grayLight};
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  padding: 1rem;

  > div:first-child {
    flex: 0 1 6%;
    font-size: 0.85rem;
    margin-right: 1rem;
  }

  > div:nth-child(2) {
    flex-grow: 1;
  }

  > div:nth-child(3) {
    width: 15px;
    // flex-grow: 0.25;
  }
  > div:nth-child(4) {
    min-width: 67.31px;
    text-align: right;
  }
`;

const OrderSummaryItem = (props) => {
  const { data, canEdit, totalOrder } = props;
  const total = data.wrapping.price * parseFloat(data.quantity);

  async function removeItem(data, totalOrder) {
    await props.removeItem(data);
    if (totalOrder < 2) {
      props.history.push("/ordenar/");
    }
  }

  function subtitile(data) {
    let title = [];
    if (data.wrapping) {
      title.push(data.wrapping.title);
    }
    if (data.bowlOptions && data.bowlOptions.length) {
      data.bowlOptions.forEach((ele) => {
        title.push(ele.title);
      });
    }
    if (data.proteins && data.proteins.length) {
      data.proteins.forEach((ele) => {
        title.push(ele.title);
      });
    }
    if (data.complements && data.complements.length) {
      data.complements.forEach((ele) => {
        title.push(ele.title);
      });
    }
    if (data.sauces && data.sauces.length) {
      data.sauces.forEach((ele) => {
        title.push(ele.title);
      });
    }
    if (data.toppings && data.toppings.length) {
      data.toppings.forEach((ele) => {
        title.push(ele.title);
      });
    }

    return title.join(', ');
  }

  return (
    <Wrapper>
      <div>{data.quantity}</div>
      <div>
        <Title>
          {data.wrapping.category} {data.name && `(${data.name})`}
        </Title>
        <Subtitle>{subtitile(data)}</Subtitle>
      </div>
      <div>
        {canEdit && (
          <DeleteButton
            icon={faTrash}
            onClick={() => removeItem(data, totalOrder)}
          />
        )}
      </div>
      <div>
        <Title>{formatPrice(total)}</Title>
      </div>
    </Wrapper>
  );
};

OrderSummaryItem.propTypes = {
  removeItem: PropTypes.func,
  canEdit: PropTypes.bool,
};

export default OrderSummaryItem;
