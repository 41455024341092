import React, { Component } from "react";

import { isRestaurantOpen } from "../helpers";

import api from "./api";

export const AppContext = React.createContext();

const generateOrderNumber = () =>
  `BAM-${(
    Date.now().toString(36) + Math.random().toString(36).substr(2, 5)
  ).toUpperCase()}`;

const initialState = {
  order: [],
  orderExtra: [],
  user: {},
  orderNumber: generateOrderNumber(),
  total: 0,
  tax: 0,
  deliveryAmount: 50,
  serviceFee: 0,
  isRestaurantOpen: isRestaurantOpen(),
  loadingTopBar: false,
  account: {},
};

class AppProvider extends Component {
  state = initialState;

  fetchAccount = async (item) => {
    try {
      const { account } = await api.get("/account/web");
      this.setState({ account: account });
    } catch (err) {
      console.log(err);
    }
  };

  insertContact = (data) => {
    try {
      api.post("/contact/web", data);
    } catch (err) {
      console.log(err);
    }
  };

  setExtraItem = async (item) => {
    await this.setState({ orderExtra: [...item] }, this.updateTotal);
  };
  setLoadingTopBar = (show) => {
    this.setState({ loadingTopBar: show });
  };
  removeItemExtra = async (item) => {
    let extra = [...this.state.orderExtra];

    extra = extra.filter((ex) => {
      return ex.id !== item.id;
    });
    this.setState({ orderExtra: [...extra] }, this.updateTotal);
  };

  componentDidMount = async () => {
    await this.fetchAccount();
    const localStorageRef = localStorage.getItem("orderBambanSushi");

    if (localStorageRef) {
      this.setState({ order: JSON.parse(localStorageRef) }, this.updateTotal);
    }

    const localStorageRefExtra = localStorage.getItem("orderExtraBambanSushi");

    if (localStorageRefExtra) {
      this.setState(
        { orderExtra: JSON.parse(localStorageRefExtra) },
        this.updateTotal
      );
    }

    // We need to check if the restaurant is open
    this.setState({
      isRestaurantOpen: isRestaurantOpen(this.state.account),
    });
  };

  componentDidUpdate() {
    localStorage.setItem("orderBambanSushi", JSON.stringify(this.state.order));
    localStorage.setItem(
      "orderExtraBambanSushi",
      JSON.stringify(this.state.orderExtra)
    );
  }

  addItem = (item) => {
    this.setState({ order: [...this.state.order, item] }, this.updateTotal);
  };

  removeItem = (item) => {
    const index = this.state.order.indexOf(item);

    this.setState(
      {
        order: [
          ...this.state.order.slice(0, index),
          ...this.state.order.slice(index + 1),
        ],
      },
      this.updateTotal
    );
  };

  updateTotal = () => {
    if (this.state.order.length < 1) return;
    const itemTotals = this.state.order.map((order) => order.totalPrice);
    let ExtraTotal = 0;
    this.state.orderExtra.forEach((order) => {
      ExtraTotal += parseFloat(order.quantity) * parseFloat(order.price);
    });
    let total = itemTotals.reduce((prev, index) => prev + index, 0);
    const orderTotalWithoutTax = (total + ExtraTotal) / 1.18;
    const tax = orderTotalWithoutTax * 0.18;
    const serviceFee = orderTotalWithoutTax * 0.1;
    total += this.state.deliveryAmount;
    this.setState({
      total: total + ExtraTotal,
      tax,
      serviceFee,
    });
  };

  addUserData = (user) => {
    let deliveryAmount = 50
    if (user.takeout) {
      deliveryAmount = 0
    }
    this.setState({ user, deliveryAmount }, this.updateTotal);
  };

  resetState = () => {
    this.setState(initialState);
  };

  render() {
    return (
      <AppContext.Provider
        value={{
          account: this.state.account,
          isRestaurantOpen: this.state.isRestaurantOpen,
          loadingTopBar: this.state.loadingTopBar,
          order: this.state.order,
          orderExtra: this.state.orderExtra,
          user: this.state.user,
          orderNumber: this.state.orderNumber,
          total: this.state.total,
          tax: this.state.tax,
          deliveryAmount: this.state.deliveryAmount,
          serviceFee: this.state.serviceFee,
          insertContact: this.insertContact,
          setLoadingTopBar: this.setLoadingTopBar,
          setExtraItem: this.setExtraItem,
          removeItemExtra: this.removeItemExtra,
          addItem: this.addItem,
          removeItem: this.removeItem,
          addUserData: this.addUserData,
          resetState: this.resetState,
        }}
      >
        {this.props.children}
      </AppContext.Provider>
    );
  }
}

export default AppProvider;
