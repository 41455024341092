import React from 'react';
import styled from 'styled-components';
import MediaQuery from 'react-responsive';
import { Container, Row, Column, Button } from 'styled-bootstrap-components';

import { AppContext } from '../AppProvider';
import Header from '../Header';
import OrderSummary from '../OrderSummary';
import CheckoutForm from '../CheckoutForm';
import { Layout, ButtonLink } from '../styled';
import './Checkout.css';
const Wrapper = styled.section`
  background: white;
  padding: 1rem;
`;

const LoadingTopBar = styled.div`
  position: fixed;
  z-index: 9999;
  font-size: 1rem;
  background: white;
  width: 100%;
  padding: 0.5rem;
  text-align: center;
  top: 0;
  left: 0;
  box-shadow: 0px 0px 8px 1px #b1b1b1;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
`
const Checkout = props => (
  <div>
    <MediaQuery maxDeviceWidth={767}>
      <Header />
    </MediaQuery>
    <Layout>
      <AppContext.Consumer>
        {context => (
          <Container fluid>
            {context.loadingTopBar &&
            (<LoadingTopBar>Procesando orden ... </LoadingTopBar>)
            }
            <Row className="checkout-row">
              <Column md={8}>
                <Wrapper>
                  <CheckoutForm addUserData={context.addUserData} context={context} history={props.history} />
                </Wrapper>
              </Column>
              {/* <MediaQuery minDeviceWidth={768}> */}
                <Column md={4}>
                  <OrderSummary
                    history={props.history}
                    order={context.order}
                    orderExtra={context.orderExtra}
                    total={context.total}
                    tax={context.tax}
                    deliveryAmount={context.deliveryAmount}
                    serviceFee={context.serviceFee}
                    removeItem={context.removeItem}
                    removeItemExtra={context.removeItemExtra}
                    canEdit
                  />
                  <Button lg bg="green" color="white" width="100%" mt="1rem">
                    <ButtonLink to="/ordenar">+ Agregar otro plato</ButtonLink>
                  </Button>
                </Column>
              {/* </MediaQuery> */}
            </Row>
          </Container>
        )}
      </AppContext.Consumer>
    </Layout>
  </div>
);

export default Checkout;
