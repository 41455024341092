export const surpriseType = [
  {
    key: 'surpriseRoll',
    title: 'Rollo',
    display: 'ROLLO',
    category: 'surpriseType',
  },
  {
    key: 'surpriseTemaki',
    title: 'Cono',
    display: 'CONO',
    category: 'surpriseType',
  },
  {
    key: 'surpriseBowl',
    title: 'Bowl',
    display: 'BOWL',
    category: 'surpriseType',
  },
  {
    key: 'surpriseOther',
    title: 'Otro',
    display: 'OTRO',
    category: 'surpriseType',
  },
];

export const surpriseDetails = [
  {
    key: 'noSalsa',
    title: 'Sin Salsa',
    display: 'SIN SALSA',
    category: 'surpriseDetails',
  },
  {
    key: 'noVegetales',
    title: 'Sin Vegetales',
    display: 'SIN VEGETALES',
    category: 'surpriseDetails',
  },
  {
    key: 'noCamarones',
    title: 'Sin Camarones',
    display: 'SIN CAMArONES',
    category: 'surpriseDetails',
  },
  {
    key: 'noMayo',
    title: 'Sin Mayonesa',
    display: 'SIN MAYONESA',
    category: 'surpriseDetails',
  },
  {
    key: 'noFrito',
    title: 'Nada Frito',
    display: 'NADA FrITO',
    category: 'surpriseDetails',
  },
  {
    key: 'noCrudo',
    title: 'Nada Crudo',
    display: 'NADA CrUDO',
    category: 'surpriseDetails',
  },
  {
    key: 'noMar',
    title: 'Nada Del Mar',
    display: 'NADA DEL MAr',
    category: 'surpriseDetails',
  },
  {
    key: 'noTierra',
    title: 'Nada Terrestre',
    display: 'NADA TErrESTrE',
    category: 'surpriseDetails',
  },
];
