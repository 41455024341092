export default {
  colors: {
    primary: '#a10204',
    yellow: '#FFF23E',
    green: '#11AA36',
    grayDark: '#373535',
    gray: '#9B9B9B',
    grayLight: '#DFDFDF',
    icecream: '#FAF6F2',
    whiter: '#FDFDFC',
  },
  fonts: {
    groovy: 'Oneer',
  },
};
